import axios from 'axios';
import { getToken } from '../Context/User.context';
import { getCookie } from './Utils.service';

export default axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getToken()}`
  },
});