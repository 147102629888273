import { toast } from 'react-toastify';
export const DAYS_MINUTES = 1_440;

export const showSuccessToast = (text: string) => {
    toast.success(text, {
        position: "top-center",
        autoClose: 5000
    });
}

export const showWarningToast = (text: string) => {
    toast.warn(text, {
        position: "top-center",
        autoClose: 5000
    });
}

export const showErrorToast = (text: string) => {
    toast.error(text, {
        position: "top-center",
        autoClose: 5000
    });
}

export const getCookie = (name: string) => {
    let cookieFind = ""
    document.cookie.split(";").forEach((c) => {
        if(c?.split("=")[0].trim() === name) {
            cookieFind = c?.split("=")[1]
        }
    })
    return cookieFind
}

export const convertDateToUTC = (date: Date) => { 
    return new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    ); 
  }

export const formatDate = (date: string) => {
    let d = new Date(date)
    return `${d.getDate()}-${d.getMonth() + 1 < 10 ? "0"+(d.getMonth() + 1) : d.getMonth() + 1}-${d.getFullYear()} ${d.getHours()< 10 ? "0"+(d.getHours()) : d.getHours()}:${d.getMinutes()< 10 ? "0"+(d.getMinutes()) : d.getMinutes()}`
}

export const formatDateInput = (date: string) => {
    let d = new Date(date)
    return `${d.getFullYear()}-${d.getMonth() + 1 < 10 ? "0"+(d.getMonth() + 1) : d.getMonth() + 1}-${d.getDate()}`
}

export const setCookie = (name: string, value: string) => {
    document.cookie = `${name}=${value}`
}

export const formatMinutesToReadableHour = (value: number) => {
    if (value && value > 0) {
      return  `${addZero(Math.floor((value % DAYS_MINUTES) / 60 ))}:${addZero(Math.floor(value%60))}`;
    } else {
      return '--:--';
    }
}

export const formatReadableHourToMinutes = (value: string) => {
    if (value) {
      const splited = value.split(':');
      return +splited[0] * 60 + +splited[1];
    }
    return '00:00';
  }

export const addZero = (num: number) => (num < 10 ? `0${num}` : num);
