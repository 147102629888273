import React from 'react'

type Props = {
  customClass?: string,
  icon?: string,
  onClick?: any
}

const FloatingButton = (props: Props) => {
  return (
    <div className={`floating_button ${props.customClass}`} onClick={props.onClick}>
      <img src={props.icon} alt="Icon Floating button" className="icon" />
    </div>
  )
}

export default FloatingButton