import React from 'react'

type Props = {
    id?: string,
    customClassLabel?: string,
    label?: string,
    data: Array<any>,
    value: string,
    labelOption: string,
    customClass ?: string,
    onChange?: any,
    currentValue?: any,
    isReadOnly?: boolean
}

const SelectWithLabel = (props: Props) => {

    const getAttributeObjectByName = (field: string, obj: any) => {
        return new Map(Object.entries(obj)).get(field) as string
    }

    return (
        <>
            <label htmlFor={props.id} className={props.customClassLabel}>{props.label}</label>
            <select name="select_loop" id={props.id} className={props.customClass} onChange={props.onChange} value={props?.currentValue} disabled={props?.isReadOnly}>
                {
                    props.data?.map((op: any) => (
                        <option value={getAttributeObjectByName(props.value, op)} className="basic_option">{getAttributeObjectByName(props.labelOption, op)}</option>
                    ))
                }
            </select>
        </>
    )
}

export default SelectWithLabel