import React, { useEffect } from 'react'
import InputWithLabel from '../../generic/InputWithLabel.generic'
import { formatDate, formatMinutesToReadableHour } from '../../services/Utils.service'

type Props = {
    data?: any
}

const PassengerForm = (props: Props) => {
    return (
        <>
        { props.data ?
            <div className="form_details">
                <h1 className="flight_name">Aircraft : {props.data?.aircraft.reg}</h1>
                <InputWithLabel
                    type="text"
                    label="Date"
                    value={formatDate(props.data?.date)}
                    customClass='basic_input mt-1'
                    customClassLabel='input_label mt-3'></InputWithLabel>
                <InputWithLabel
                    type="text"
                    label="Time out from camp"
                    value={formatMinutesToReadableHour(
                        props.data.takeoffTimeRally -
                        props.data.aircraft.aircraftType.boardingTime -
                        props.data.departureAirport.transferTime
                    )}
                    customClass='basic_input mt-1'
                    customClassLabel='input_label mt-3'></InputWithLabel>
                <InputWithLabel
                    type="text"
                    label="Take off"
                    value={formatMinutesToReadableHour(
                        props.data.takeoffTimeUtc
                    )}
                    customClass='basic_input mt-1'
                    customClassLabel='input_label mt-3'></InputWithLabel>
                <InputWithLabel
                    type="text"
                    label="Landing"
                    value={formatMinutesToReadableHour(
                        props.data.landingTimeUtc
                    )}
                    customClass='basic_input mt-1'
                    customClassLabel='input_label mt-3'></InputWithLabel>
                <InputWithLabel
                    type="text"
                    label="Time in to camp"
                    value={formatMinutesToReadableHour(
                        props.data.landingTimeRally +
                        props.data.aircraft.aircraftType.disembarquingTime +
                        props.data.arrivalAirport.transferTime
                    )}
                    customClass='basic_input mt-1'
                    customClassLabel='input_label mt-3'></InputWithLabel>
                <InputWithLabel
                    type="text"
                    label="Mission"
                    value={props.data.mission.name}
                    customClass='basic_input mt-1'
                    customClassLabel='input_label mt-3'></InputWithLabel>
                <InputWithLabel
                    type="text"
                    label="Aircraft Type"
                    value={props.data.aircraft.aircraftType.name}
                    customClass='basic_input mt-1'
                    customClassLabel='input_label mt-3'></InputWithLabel>
                <InputWithLabel
                    type="text"
                    label="From (Civil)"
                    value={props.data.departureAirport.civilCode}
                    customClass='basic_input mt-1'
                    customClassLabel='input_label mt-3'></InputWithLabel>
                <InputWithLabel
                    type="text"
                    label="To (Civil)"
                    value={props.data.arrivalAirport.civilCode}
                    customClass='basic_input mt-1 mb-3'
                    customClassLabel='input_label mt-3'></InputWithLabel>
            </div>
        :
            <></>
        }
        </>
    )
}

export default PassengerForm