import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getToken, UserContext } from '../Context/User.context'
import FloatingButton from '../generic/FloatingButton.generic'
import Header from '../generic/Header.generic'
import Input from '../generic/Input.generic'
import List from '../generic/List.generic'
import { getByDate } from '../services/Flight.service'
import { getCookie, showErrorToast } from '../services/Utils.service'

const Flights = () => {
    const [allFlights, setAllFlights] = useState<Array<any>>([])
    const { user } = useContext<any>(UserContext)
    const [selectedDate, setSelectedDate] = useState<any>()
    const navigate = useNavigate()
    const { selectedDateFlight, setSelectedDateFlight } = useContext<any>(UserContext)

    useEffect(() => {
        let date = null
        if(selectedDateFlight == "") {
            date = new Date()
            setSelectedDate(`${date.getFullYear()}-${date.getMonth() + 1 < 10 ? "0"+(date.getMonth() + 1) : date.getMonth() + 1}-${date.getDate()}`)
            setSelectedDateFlight(`${date.getFullYear()}-${date.getMonth() + 1 < 10 ? "0"+(date.getMonth() + 1) : date.getMonth() + 1}-${date.getDate()}`)
        } else {
            setSelectedDate(selectedDateFlight)
            date = new Date(selectedDateFlight)
        }
        getByDate(date, date).then((result: any) => {
            if(user?.roleName == "CREW") {
                let fl: Array<any> = result?.data?.filter((f: any) => f.aircraft.airline.name == user?.airlineName)
                fl.sort((a: any, b: any) => a?.takeoffTimeRally - b?.takeoffTimeRally)
                setAllFlights([...fl.filter((f: any) => f.flightStatusId != 3)])
            } else {
                let fl: Array<any> = result?.data
                fl.sort((a: any, b: any) => a?.takeoffTimeRally - b?.takeoffTimeRally)
                setAllFlights([...fl.filter((f: any) => f.flightStatusId != 3)])
            }
        }, (error: any) => {
            showErrorToast(error)
        })
    }, [])

    const queryFlightByDate = (value: string) => {
        setSelectedDate(value)
        setSelectedDateFlight(value)
        let date = new Date(value)
        getByDate(date, date).then((result: any) => {
            if(user?.roleName == "CREW") {
                let fl: Array<any> = result?.data?.filter((f: any) => f.aircraft.airline.name == user?.airlineName)
                fl.sort((a: any, b: any) => a?.takeoffTimeRally - b?.takeoffTimeRally)
                setAllFlights([...fl.filter((f: any) => f.flightStatusId != 3)])
            } else {
                let fl: Array<any> = result?.data
                fl.sort((a: any, b: any) => a?.takeoffTimeRally - b?.takeoffTimeRally)
                setAllFlights([...fl.filter((f: any) => f.flightStatusId != 3)])
            }
        }, (error: any) => {
            showErrorToast(error)
        })
    }

    const openFlightDetail = (flightId: number) => {
        navigate(`/flight_info?id=${flightId}`)
    }

    return (
        <div className="page">
            <Header
                from='flights'
                backUrl="/dashboard"
                title="Flights"></Header>
            <Input
                type="date"
                value={selectedDate}
                customClass='basic_input mt-5 mb-5'
                onChange={(e: any) => queryFlightByDate(e.target.value)}></Input>
            {
                allFlights.length > 0 ?
                    <List
                        customClass='list_flight'
                        data={allFlights}
                        icon='img/flight.svg'
                        onClick={(e: any) => {openFlightDetail(e)}}></List>
                :
                    <div className="no_data_block">
                        <img src="img/details_vol.svg" alt="Icon no data" className="icon_no_data" />
                        <span className="text_no_data mt-5">No flight found for this period</span>
                    </div>
            }
            {
                user?.roleName == "DISPATCH" ?
                    <FloatingButton
                        customClass={'background_blue'}
                        icon="img/add_icon.svg"
                        onClick={() => { navigate("/add_flight") }}></FloatingButton>
                :
                    <></>
            }
        </div>
    )
}

export default Flights