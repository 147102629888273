import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import CrewForm from '../components/Planning/CrewForm.component'
import DispatchForm from '../components/Planning/DispatchForm.component'
import PassengerForm from '../components/Planning/PassengerForm.component'
import { getFieldsPlanningByRole } from '../constants/Constant.constant'
import { UserContext } from '../Context/User.context'
import Header from '../generic/Header.generic'
import { getFlightById } from '../services/Flight.service'
import { showErrorToast } from '../services/Utils.service'

const FlightDetail = () => {
    const { user } = useContext<any>(UserContext)
    const [flight, setFlight] = useState<any>(null)
    const { search } = useLocation()

    useEffect(() => {
        let flightId = search.replace("?", '').split("=")[1]
        getFlightById(+flightId).then((result: any) => {
            if(result.data) {
                setFlight(result.data)
            } else {
                showErrorToast("An error occured while fetching the flight details")
            }
        }, (error: any) => {
            showErrorToast(`${error.response.data.error} - ${error.response.data.message}`)
        })
    },[])

    return (
        <div className="page">
            <Header
                from='detail_flight'
                backUrl="/planning"
                title="Details"></Header>
            {
                user?.roleName === "PASSENGERS" ?
                    <PassengerForm
                        data={flight}></PassengerForm>
                :
                    user?.roleName === "CREW" ?
                        <CrewForm
                            data={flight}></CrewForm>
                    :
                        <DispatchForm
                            data={flight}></DispatchForm>
            }
        </div>
    )
}

export default FlightDetail