import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import BackButton from '../generic/BackButton.generic'
import Button from '../generic/Button.generic'
import InputWithLabel from '../generic/InputWithLabel.generic'
import { requestPassword } from '../services/User.service'
import { showErrorToast, showSuccessToast } from '../services/Utils.service'

const ResetPassword = () => {
  const [email, setEmail] = useState<string>("")
  const navigate = useNavigate()

  const sendEmailReset = () => {
    requestPassword({email}).then((result: any) => {
      showSuccessToast("Email sent. Check your mailbox")
    }, (error: any) => {
      showErrorToast(`${error.response.data.error} - ${error.response.data.message}`)
    })
  }

  return (
    <div className='page'>
      <BackButton
        customClass='back_button'
        onClick={() => navigate("/")}></BackButton>
      <img src="img/cps_logo.png" alt="Logo CPS" className="logo_login mt-12" />
      <h1 className="title_form mt-10">Reset password</h1>
      <InputWithLabel
        type="email"
        label="Email"
        value={email}
        placeholder='Enter email'
        customClass='basic_input mt-1'
        customClassLabel='input_label mt-5'
        onChange={(e: any) => setEmail(e.target.value)}></InputWithLabel>
      <Button
        onClick={() => { sendEmailReset() }}
        label="Send email"
        customClass="basic_button mt-5"></Button>
    </div>
  )
}

export default ResetPassword