import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import FloatingButton from '../../generic/FloatingButton.generic'
import InputWithLabel from '../../generic/InputWithLabel.generic'
import { edit } from '../../services/Flight.service'
import { formatDate, formatMinutesToReadableHour, formatReadableHourToMinutes, showErrorToast, showSuccessToast } from '../../services/Utils.service'

type Props = {
    data?: any
}

const CrewForm = (props: Props) => {
    const [inEdit, setInEdit] = useState<boolean>(false)
    const [flightInEdit, setFlightInEdit] = useState<any>(props.data)
    const [toUpdate, setToUpdate] = useState<any>({})
    const navigate = useNavigate()

    useEffect(() => {
      setFlightInEdit(JSON.parse(JSON.stringify(props.data)))
    }, [props.data])

    const updateFlight = (value: any, field: string) => {
        switch(field) {
            case 'takeoffTimeTlb':
                flightInEdit.takeoffTimeTlb = formatReadableHourToMinutes(value)
                toUpdate.takeoffTimeTlb = formatReadableHourToMinutes(value)
                setToUpdate(JSON.parse(JSON.stringify(toUpdate)))
                break;
            case 'landingTimeTlb':
                flightInEdit.landingTimeTlb = formatReadableHourToMinutes(value)
                toUpdate.landingTimeTlb = formatReadableHourToMinutes(value)
                setToUpdate(JSON.parse(JSON.stringify(toUpdate)))
                break;
            case 'fuelOnDeparture':
                flightInEdit.fuelOnDeparture = value
                toUpdate.fuelOnDeparture = value
                setToUpdate(JSON.parse(JSON.stringify(toUpdate)))
                break;
            case 'fuelOnArrival':
                flightInEdit.fuelOnArrival = value
                toUpdate.fuelOnArrival = value
                setToUpdate(JSON.parse(JSON.stringify(toUpdate)))
                break;
            case 'passengerCounter':
                flightInEdit.passengerCounter = value
                toUpdate.passengerCounter = value
                setToUpdate(JSON.parse(JSON.stringify(toUpdate)))
                break;
            case 'remark':
                flightInEdit.remark = value
                toUpdate.remark = value
                setToUpdate(JSON.parse(JSON.stringify(toUpdate)))
                break;
            default:
                break;
        }
        setFlightInEdit(JSON.parse(JSON.stringify(flightInEdit)))
    }

    const saveChange = () => {
        edit(toUpdate, flightInEdit.id).then((result: any) => {
            if(result?.status === 200) {
                showSuccessToast("Flight updated successfully")
                navigate("/flights")
            }
        },(error: any) => {
            showErrorToast(`${error.response.data.error} - ${error.response.data.message}`)
        })
        setInEdit(false)
    }

    return (
        <>
            { flightInEdit ?
                <div className="form_details">
                    <h1 className="flight_name">{inEdit ? "(Edit Mode)" : ""} Aircraft : {flightInEdit?.aircraft.reg}</h1>
                    <InputWithLabel
                        type="time"
                        readonly={!inEdit}
                        label="Take off time (TLB)"
                        value={formatMinutesToReadableHour(flightInEdit?.takeoffTimeTlb)}
                        onChange={(e: any) => updateFlight(e.target.value, "takeoffTimeTlb")}
                        customClass='basic_input mt-1'
                        customClassLabel='input_label mt-3'></InputWithLabel>
                    <InputWithLabel
                        type="time"
                        readonly={!inEdit}
                        label="Landing time (TLB)"
                        value={formatMinutesToReadableHour(flightInEdit?.landingTimeTlb)}
                        onChange={(e: any) => updateFlight(e.target.value, "landingTimeTlb")}
                        customClass='basic_input mt-1'
                        customClassLabel='input_label mt-3'></InputWithLabel>
                    <InputWithLabel
                        type="text"
                        readonly={!inEdit}
                        label="Fuel departure (L)"
                        onChange={(e: any) => updateFlight(e.target.value, "fuelOnDeparture")}
                        value={flightInEdit?.fuelOnDeparture}
                        customClass='basic_input mt-1'
                        customClassLabel='input_label mt-3'></InputWithLabel>
                    <InputWithLabel
                        type="text"
                        readonly={!inEdit}
                        label="Fuel arrival (L)"
                        onChange={(e: any) => updateFlight(e.target.value, "fuelOnArrival")}
                        value={flightInEdit?.fuelOnArrival}
                        customClass='basic_input mt-1'
                        customClassLabel='input_label mt-3'></InputWithLabel>
                    <InputWithLabel
                        type="text"
                        readonly={!inEdit}
                        label="Pax"
                        onChange={(e: any) => updateFlight(e.target.value, "passengerCounter")}
                        value={flightInEdit?.passengerCounter}
                        customClass='basic_input mt-1'
                        customClassLabel='input_label mt-3'></InputWithLabel>
                    <InputWithLabel
                        type="text"
                        readonly={!inEdit}
                        label="Remark"
                        onChange={(e: any) => updateFlight(e.target.value, "remark")}
                        isTextArea={true}
                        value={flightInEdit?.remark}
                        customClass='basic_text_area mt-1'
                        customClassLabel='input_label mt-3'></InputWithLabel>
                    {
                        !inEdit ?
                            <FloatingButton
                                customClass={'background_blue'}
                                icon="img/edit_icon.svg"
                                onClick={() => {setInEdit(true)}}></FloatingButton>
                        :
                            <FloatingButton
                            customClass={'background_green'}
                            icon="img/save_icon.svg"
                            onClick={() => {saveChange()}}></FloatingButton>
                    }
                </div>
            :
                <></>
            }
            </>
    )
}

export default CrewForm