import React from 'react'

type Props = {
    customClass?: string,
    icon?: string,
    onClick?: any,
    label?: string
}

const Card = (props: Props) => {
  return (
    <div onClick={props.onClick} className={`card ${props.customClass}`}>
        <img src={props.icon} alt="Icon Card" className="icon_card" />
        <label className="label_card mt-5">{props.label}</label>
    </div>
  )
}

export default Card