import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { UserContext } from '../Context/User.context'
import Card from '../generic/Card.generic'
import Header from '../generic/Header.generic'

const Dashboard = () => {
  const { user } = useContext<any>(UserContext)
  const navigate = useNavigate()

  return (
    <div className="page_table">
      <Header
        backUrl='/'
        title="Dashboard"
        from="dashboard"></Header>
      <div className="cards">
        {
          user?.roleName !== "PASSENGERS" ?
            <Card
              icon="img/details_vol.svg"
              customClass='basic_card'
              label="Flight"
              onClick={() => navigate("/flights")}></Card>
          :
            <></>
        }
        <Card
          icon="img/calendar.svg"
          customClass='basic_card'
          label="Planning"
          onClick={() => navigate("/planning")}></Card>
      </div>
    </div>
  )
}

export default Dashboard