import React from 'react'
import Input from './Input.generic'

type Props = {
    type?: string,
    value?: string | number,
    onChange?: any,
    customClass?: string,
    min?: number,
    max?: number,
    label?: string,
    customClassLabel?: string,
    id?: string,
    placeholder?: string,
    isTextArea?: boolean,
    readonly?: boolean
}

const InputWithLabel = (props: Props) => {
  return (
    <>
        <label htmlFor={props.id} className={props.customClassLabel}>{props.label}</label>
        <Input
            id={props.id}
            type={props.type}
            value={props.value}
            onChange={props.onChange}
            customClass={props.customClass}
            min={props.min}
            readonly={props.readonly}
            isTextArea={props.isTextArea}
            placeholder={props.placeholder}
            max={props.max}></Input>
    </>
  )
}

export default InputWithLabel