import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getFieldsPlanningByRole } from '../constants/Constant.constant'
import { UserContext } from '../Context/User.context'
import Header from '../generic/Header.generic'
import Link from '../generic/Link.generic'
import List from '../generic/List.generic'
import { getByDate } from '../services/Flight.service'
import { convertDateToUTC, showErrorToast } from '../services/Utils.service'

const Planning = () => {
    const [flights, setFlights] = useState<Array<any>>([])
    const [allFlights, setAllFlights] = useState<Array<any>>([])
    const { user } = useContext<any>(UserContext)
    const [dateSelected, setDateSelected] = useState<number>(0)
    const navigate = useNavigate()

    useEffect(() => {
        //mocked
        let today = new Date(2023,0,12)

        // let today = new Date()
        // let tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1) 
        getByDate(today, today).then((result: any) => {
            let fl: Array<any> = result?.data?.filter((f: any) => f?.flightStatusId != 3)
            fl.sort((a: any, b: any) => a?.takeoffTimeRally - b?.takeoffTimeRally)
            setAllFlights([...fl])
        }, (error: any) => {
            showErrorToast(`${error.response.data.error} - ${error.response.data.message}`)
        })
    }, [])

    const updateDisplayedFlights = (value: number) => {
        setDateSelected(value)
        let today = new Date()
        let tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + value)
        // let tomorrow = new Date(2021,12,(13 + value))
        getByDate(tomorrow, tomorrow).then((result: any) => {
            let fl: Array<any> = result?.data?.filter((f: any) => f?.flightStatusId != 3)
            fl.sort((a: any, b: any) => a?.takeoffTimeRally - b?.takeoffTimeRally)
            setAllFlights([...fl])
        }, (error: any) => {
            showErrorToast(`${error.response.data.error} - ${error.response.data.message}`)
        })
    }

    useEffect(() => {
        console.log(user)
        if(user?.roleName == "CREW") {
            let crewFlight = []
            crewFlight = [...allFlights.filter((f: any) => f?.aircraft?.airline?.name == user.airlineName)]
            setFlights(crewFlight)
        } else if(user?.roleName == "PASSENGERS") {
            let crewFlight = []
            crewFlight = [...allFlights.filter((f: any) => f.mission.name != "FERRY" && f.mission.name != "STBY EVASAN")]
            setFlights(crewFlight)
        } else {
            setFlights(allFlights)
        }
    }, [allFlights])

    const openFlightDetail = (flightId: number) => {
        navigate(`/detail_flight?id=${flightId}`)
    }

    return (
        <div className="page">
            <Header
                from='planning'
                backUrl="/dashboard"
                title="Planning"></Header>
            <div className="date_selector">
                <Link
                    label="Today"
                    customClass={dateSelected == 0 ? "selected_link" : ""}
                    onClick={() => updateDisplayedFlights(0)}></Link>
                <Link
                    label="Tomorrow"
                    customClass={dateSelected == 1 ? "selected_link" : ""}
                    onClick={() => updateDisplayedFlights(1)}></Link>
            </div>
            {
                allFlights.length > 0 ?
                    <List
                        customClass='list_flight'
                        data={flights}
                        icon='img/flight.svg'
                        onClick={(e: any) => {openFlightDetail(e)}}></List>
                :
                    <div className="no_data_block">
                        <img src="img/details_vol.svg" alt="Icon no data" className="icon_no_data" />
                        <span className="text_no_data mt-5">No flight found for this period</span>
                    </div>
            }
        </div>
    )
}

export default Planning