import React, { useEffect } from 'react'
import InputWithLabel from '../../generic/InputWithLabel.generic'
import { formatDate, formatMinutesToReadableHour } from '../../services/Utils.service'

type Props = {
    data?: any
}

const CrewForm = (props: Props) => {
    return (
        <>
        { props.data ?
            <div className="form_details">
                <h1 className="flight_name">Aircraft : {props.data?.aircraft.reg}</h1>
                <InputWithLabel
                    type="text"
                    label="Date"
                    value={formatDate(props.data?.date)}
                    customClass='basic_input mt-1'
                    customClassLabel='input_label mt-3'></InputWithLabel>
                <InputWithLabel
                    type="text"
                    label="Boarding time"
                    value={formatMinutesToReadableHour(props.data.takeoffTimeRally - props.data.aircraft.aircraftType.boardingTime)}
                    customClass='basic_input mt-1'
                    customClassLabel='input_label mt-3'></InputWithLabel>
                <InputWithLabel
                    type="text"
                    label="Take off time rally"
                    value={formatMinutesToReadableHour(
                        props.data.takeoffTimeRally
                    )}
                    customClass='basic_input mt-1'
                    customClassLabel='input_label mt-3'></InputWithLabel>
                <InputWithLabel
                    type="text"
                    label="Landing time rally"
                    value={formatMinutesToReadableHour(
                        props.data.landingTimeRally
                    )}
                    customClass='basic_input mt-1'
                    customClassLabel='input_label mt-3'></InputWithLabel>
                <InputWithLabel
                    type="text"
                    label="Flight time"
                    value={formatMinutesToReadableHour(
                        props.data.flightTimeRally
                    )}
                    customClass='basic_input mt-1'
                    customClassLabel='input_label mt-3'></InputWithLabel>
                <InputWithLabel
                    type="text"
                    label="Mission"
                    value={props.data.mission.name}
                    customClass='basic_input mt-1'
                    customClassLabel='input_label mt-3'></InputWithLabel>
                <InputWithLabel
                    type="text"
                    label="Aircraft Type"
                    value={props.data.aircraft.aircraftType.name}
                    customClass='basic_input mt-1'
                    customClassLabel='input_label mt-3'></InputWithLabel>
                <InputWithLabel
                    type="text"
                    label="Registration"
                    value={props.data.aircraft.reg}
                    customClass='basic_input mt-1'
                    customClassLabel='input_label mt-3'></InputWithLabel>
                <InputWithLabel
                    type="text"
                    label="Squawk"
                    value={props.data.aircraft.squawk}
                    customClass='basic_input mt-1'
                    customClassLabel='input_label mt-3'></InputWithLabel>
                <InputWithLabel
                    type="text"
                    label="Callsign"
                    value={props.data.aircraft.callsign}
                    customClass='basic_input mt-1'
                    customClassLabel='input_label mt-3'></InputWithLabel>
                <InputWithLabel
                    type="text"
                    label="From (ICAO)"
                    value={props.data.departureAirport.icaoCode}
                    customClass='basic_input mt-1'
                    customClassLabel='input_label mt-3'></InputWithLabel>
                <InputWithLabel
                    type="text"
                    label="To (ICAO)"
                    value={props.data.arrivalAirport.icaoCode}
                    customClass='basic_input mt-1 mb-3'
                    customClassLabel='input_label mt-3'></InputWithLabel>
            </div>
        :
            <></>
        }
        </>
    )
}

export default CrewForm