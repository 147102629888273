import React, { useContext } from 'react'
import Header from '../generic/Header.generic'
import {
    useLocation, useNavigate
  } from "react-router-dom";
import InputWithLabel from '../generic/InputWithLabel.generic';
import { UserContext } from '../Context/User.context';
import Link from '../generic/Link.generic';

const Profile = () => {
    const { search } = useLocation()
    const { user, resetContext } = useContext<any>(UserContext)
    const navigate = useNavigate()

    const disconnect = () => {
        resetContext()
        navigate("/")
    }

    return (
        <div className="page">
            <Header
                backUrl={`/${search.replace("?", '').split("=")[1]}`}
                noProfileIcon={true}
                title="Profile"></Header>
            <InputWithLabel 
                type="text"
                label="Logged as"
                value={user?.email}
                customClass='basic_input mt-1'
                customClassLabel='input_label mt-5'></InputWithLabel>
            <InputWithLabel 
                type="text"
                label="Firstname"
                value={user?.firstname}
                customClass='basic_input mt-1'
                customClassLabel='input_label mt-5'></InputWithLabel>
            <InputWithLabel 
                type="text"
                label="Lastname"
                value={user?.lastname}
                customClass='basic_input mt-1'
                customClassLabel='input_label mt-5'></InputWithLabel>
            <InputWithLabel 
                type="text"
                label="Role"
                value={user?.roleName}
                customClass='basic_input mt-1'
                customClassLabel='input_label mt-5'></InputWithLabel>
            <Link
                label="Disconnect"
                customClass='link_disconnect mt-12'
                onClick={() => disconnect()}></Link>
        </div>
    )
}

export default Profile